import React from "react";
import "./index.css";

function SpinnerLoader() {
  return (
    <div className="lds-ring">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}

export default SpinnerLoader;
