import { useEffect } from "react";
import { useSelector } from "react-redux";
import "./App.css";
import { getAuth } from "./redux/slices/auth.slice";
import { RootState, useAppDispatch } from "./redux/store";
import SpinnerLoader from "./components/SpinnerLoader";

function App() {
  const dispatch = useAppDispatch();

  const { message, loading } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    dispatch(getAuth());
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        {loading ? <SpinnerLoader /> : <p>{message}</p>}
      </header>
    </div>
  );
}

export default App;
