import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../utils/interceptor";

const initialState = {
  loading: false,
  isAuthorized: false,
  message: "",
};

export const getAuth = createAsyncThunk(
  "auth/getAuth",
  async (_, { getState, rejectWithValue }) => {
    try {
      const url = window.location.href;
      const params: any = new URL(url).searchParams;

      // Extract query parameters
      const queryParameters: any = {};
      for (const [key, value] of params.entries()) {
        queryParameters[key] = value;
      }

      const authApiUrl = `${process.env.REACT_APP_BASE_API_URL}/authorization`;

      // Retrieve the required parameters for authorization
      const userId = parseInt(queryParameters.user_id);
      const username = encodeURIComponent(queryParameters.username);
      const doceboHash = queryParameters.hash;
      const authCode = queryParameters.auth_code;
      const courseId = queryParameters.course_id;
      const courseCode = queryParameters.course_code;

      const body = {
        user_id: userId,
        username: username,
        docebo_hash: doceboHash,
        auth_code: authCode,
        course_id: courseId,
        course_code: courseCode,
      };

      const response = await API.post(authApiUrl, body);
      // const response = await fetch(authApiUrl, {
      //   method: "POST",
      //   mode: "cors",
      //   credentials: "include",
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      //   body: body,
      // });

      if (response.status !== 200) {
        throw rejectWithValue("Authorization failed");
      }

      console.log("response: ", response);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || "Authorization failed"
      );
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAuth.pending, (state) => {
        state.loading = true;
        state.message = "";
      })
      .addCase(getAuth.fulfilled, (state, action) => {
        state.loading = false;
        state.isAuthorized = true;
        state.message =
          (action.payload.message as string) || "Authorization successful";
      })
      .addCase(getAuth.rejected, (state, action) => {
        state.loading = false;
        state.isAuthorized = false;
        state.message = (action.payload as string) || "Authorization failed";
      });
  },
});

export default authSlice.reducer;
