import axios, { AxiosInstance } from "axios";

const API: AxiosInstance = axios.create({
  baseURL: "",
  // withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
});

API.interceptors.request.use(
  (config) => {

    // You can modify the request config here (e.g., add headers, authentication, etc.)
    return config;
  },
  (error) => {
    // Handle request errors
    return Promise.reject(error);
  }
);

API.interceptors.response.use(
  (response) => {
    // You can modify the response data here
    return response;
  },
  (error) => {
    console.log("error", error.message);
    return error;
  }
);

export default API;
